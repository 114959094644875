<template>
  <main>
    <Row :gutter="15">
      <Col :span="17">
        <ButtonGroup shape="circle">
          <Button icon="md-refresh" @click="loadData">刷新</Button>
        </ButtonGroup>
      </Col>
      <Col :span="7">
        <searcher ref="searcher" :options="searches" @on-change="loadData"></searcher>
      </Col>
    </Row>
    <Divider/>
    <Table border :columns="columns" :data="data" @on-sort-change="changeSort"></Table>

    <Divider/>
    <pagination ref="pagination" :total="total" @on-change="loadData"/>

    <Modal v-model="cancelModal" title="取消提现">
      <Form label-position="top" class="container">
        <FormItem label="取消原因" required>
          <Input v-model="cancelMessage" type="textarea"/>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="cancelModal = false" class="mr">取消</Button>
        <Button type="primary" @click="cancel">确认取消</Button>
      </div>
    </Modal>
  </main>
</template>

<script>
export default {
  data() {
    return {
      searches: {
        user_id: "用户id",
        status: "状态：0 审核中 1 提现中  2 提现成功 3 提现失败",
        out_batch_no: "商家批次单号",
        batch_id: "微信批次单号"
      },
      cancelModal: false,
      cancelMessage: "",
      cancelItem: null,
      sort: "",
      total: 0,
      data: [],
      columns: [
        {
          title: "ID",
          key: "id",
          sortable: "custom",
          align:'center',
          minWidth: 80
        },
        {
          title: "用户",
          key: "user_id",
          sortable: "custom",
          minWidth: 180,
          align:'center',
          render: (h, params) => {
            return h(
              "div",
              {
                class: "cursor flex flex-align-center",
                on: {
                  click: () => {
                    this.$router.push("/user/detail/" + params.row.user_id);
                  }
                }
              },
              [
                h("img", {
                  attrs: {
                    src: params.row.user.avatar
                  },
                  class: "avatar-sm mr-xs"
                }),
                h("p", params.row.user.nickname)
              ]
            );
          }
        },
        {
          title: "提现金额",
          minWidth: 80,
          key: "amount",
          sortable: "custom",
          align:'center',
          render: (h, params) => {
            return h(
              "span",
              { class: "text-price text-red" },
              params.row.amount
            );
          }
        },
        {
          title: "商家批次单号",
          minWidth: 180,
          key: "out_batch_no",
          align:'center',
          sortable: "custom"
        },
        {
          title: "微信批次单号",
          minWidth: 180,
          key: "batch_id",
          align:'center',
          sortable: "custom"
        },
        {
          title: "提现成功时间",
          minWidth: 180,
          key: "create_time",
          align:'center',
          sortable: "custom"
        },
        {
          title: "状态",
          minWidth: 100,
          key: "status",
          sortable: "custom",
          align: "center",
          render: (h, params) => {
            switch (params.row.status) {
              case 0:
                return h("Tag", { props: { color: "cyan" } }, "审核中");

              case 1:
                return h("Tag", { props: { color: "purple" } }, "提现处理中");

              case 2:
                return h("Tag", { props: { color: "green" } }, "提现成功");

              case 3:
                return h("Tag", { props: { color: "red" } }, "提现失败");
            }
          }
        },
        {
          title: "操作",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            var html = [];

            if (
              this.Auth(["UserWithdraw", "withdraw"]) &&
              params.row.status < 2
            ) {
              html.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "success",
                      size: "small"
                    },
                    class: "mg-lr-xs",
                    on: {
                      click: () => {
                        this.withdraw(params.row);
                      }
                    }
                  },
                  "提现"
                )
              );
            }

            if (this.Auth(["UserWithdraw", "query"])) {
              html.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small"
                    },
                    class: "mg-lr-xs",
                    on: {
                      click: () => {
                        this.query(params.row);
                      }
                    }
                  },
                  "查询"
                )
              );
            }

            if (
              this.Auth(["UserWithdraw", "cancel"]) &&
              params.row.status < 2
            ) {
              html.push(
                h(
                  "Button",
                  {
                    props: {
                      type: "error",
                      size: "small"
                    },
                    class: "mg-lr-xs",
                    on: {
                      click: () => {
                        this.cancelItem = params.row;
                        this.cancelModal = true;
                      }
                    }
                  },
                  "取消"
                )
              );
            }

            return h("div", html);
          }
        }
      ]
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let params = {
        search_key: this.$refs.searcher.search_key,
        keywords: this.$refs.searcher.keywords,
        sort: this.sort,
        page: this.$refs.pagination.page,
        limit: this.$refs.pagination.limit
      };

      this.$http
        .get("/userWithdrawWechat/list", { params: params })
        .then(res => {
          this.total = res.total;
          this.data = res.data;
        });
    },
    changeSort(item) {
      this.sort = item.order == "normal" ? "" : item.key + "," + item.order;
      this.$refs.pagination.page = 1;
      this.loadData();
    },
    cancel() {
      this.cancelModal = false;

      this.$Modal.confirm({
        title: "确定要取消提现吗？",
        content: "如果取消提现，将会返还余额",
        onOk: res => {
          this.$http
            .post("/userWithdrawWechat/cancel/id/" + this.cancelItem.id, {
              err_des: this.cancelMessage
            })
            .then(res => {
              this.loadData();
            });
        }
      });
    },
    withdraw(item) {
      this.$Modal.confirm({
        title: "确定要开始提现吗？",
        content: "如果提现失败，请稍后点击查询按钮",
        onOk: res => {
          this.$http
            .post("/userWithdrawWechat/transfers/id/" + item.id)
            .then(res => {
              this.loadData();
            });
        }
      });
    },
    query(item) {
      this.$http
        .post("/userWithdrawWechat/getTransferInfo/id/" + item.id)
        .then(res => {
          console.log("提现查询结果",res)
        });
    }
  }
};
</script>